import { COMMUNITY_TAB } from '@/models/store/community.interface';

export const AVAILABLE_EVENT_CARD_ACTIONS = {
	like: 'like',
	download: 'download',
};

export const COMMUNITY_TABS: COMMUNITY_TAB[] = [COMMUNITY_TAB['3D_SCENES'], COMMUNITY_TAB.AI_SCENES];

export type AvailaleEventCardActions = (typeof AVAILABLE_EVENT_CARD_ACTIONS)[keyof typeof AVAILABLE_EVENT_CARD_ACTIONS];
