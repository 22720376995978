import { BlogLang } from '@/lang/blog/_blog_lang_template.type';

const br: BlogLang = {
	blog: 'Blog',
	blog_show_all: 'Veja tudo',
	blog_banner_title: 'Mais de 15.000 ativos de design 3D personalizáveis',
	blog_banner_sub: 'para UI/UX, site, design de aplicativos e muito mais.',
	blog_banner_cta: 'Registre-se gratuitamente',
	blog_article_cta_text: 'Precisa de ícones, ilustrações, personagens e maquetes 3D editáveis?',
	blog_article_cta_button_text: 'Crie agora',
};

export default br;
