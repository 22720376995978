import { BlogLang } from '@/lang/blog/_blog_lang_template.type';

const kr: BlogLang = {
	blog: '블로그',
	blog_show_all: '모두 읽기',
	blog_banner_title: '15,000개 이상의 커스터마이징 가능한 3D 디자인 에셋',
	blog_banner_sub: 'UI/UX, 웹사이트, 앱 디자인 등에 사용할 수 있습니다.',
	blog_banner_cta: '무료로 가입하기',
	blog_article_cta_text: '편집 가능한 3D 아이콘, 일러스트레이션, 캐릭터, 모형이 필요하십니까?',
	blog_article_cta_button_text: '지금 디자인하기',
};

export default kr;
