
	import { Component, Vue, Prop } from 'vue-property-decorator';
	import BodyText from '@pixcap/ui-library/components/Typography/BodyText.vue';
	import HeadingText from '@pixcap/ui-library/components/Typography/HeadingText.vue';
	import PopupMenu from '@pixcap/ui-library/components/PopupMenu.vue';
	import Button from '@pixcap/ui-library/components/Button.vue';
	import IconArrowDown from '@pixcap/ui-library/components/Icons/IconArrowDown.vue';
	import IconCheck from '@pixcap/ui-library/components/Icons/IconCheck.vue';

	@Component({
		name: 'BlogHeaderNavbarItem',
		components: {
			Button,
			BodyText,
			HeadingText,
			PopupMenu,
			IconArrowDown,
			IconCheck,
		},
		computed: {},
	})
	export default class BlogHeaderNavbarItem extends Vue {
		@Prop() name: string;
		@Prop() url: string;

		@Prop() menus: { thumbnail?: string; name: string; url: string }[];
		@Prop() footer: { name: string; url: string };
		@Prop({ default: 'center' }) openPosition: string;
		@Prop({ default: 'default' }) size: 'small' | 'default';
		showDropDownMenu = false;
		toggleNavbar(showDropDownMenu: boolean) {
			this.showDropDownMenu = showDropDownMenu;
		}

		onClickMenuButton() {
			if (this.url && window) {
				window.location.href = this.url;
			}
		}
	}
