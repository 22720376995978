
	import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
	import { mapGetters, mapState } from 'vuex';
	import { CancelTokenSource } from 'axios';
	import BodyText from '@pixcap/ui-library/components/Typography/BodyText.vue';
	import IconSearch from '@pixcap/ui-library/components/Icons/IconSearch.vue';
	import IconClose from '@pixcap/ui-library/components/Icons/IconClose.vue';

	import { IAssetsLibraryState, NAMESPACE as LIBRARY_NAMESPACE, SEARCH_ROUTES, SEARCH_TAB } from '@/models/store/pixcapassets.interface';
	import { actionsWrapper as LibraryActions, mutationsWrapper as LibraryMutations } from '@/store/pixcapassets/wrapper';
	import { highlightTextDirective, transformSearchWord } from '@/utils/StringUtils';
	import SearchBarSuggestion from '@/components/layouts/default/SearchBarSuggestion.vue';
	import { Getters as AuthGetters, GetterTypes as AuthGetterTypes, NAMESPACE as AUTH_NAMESPACE } from '@pixcap/ui-core/models/store/auth.interface';

	@Component({
		name: 'ExploreSearchBar',
		components: {
			SearchBarSuggestion,
			BodyText,
			IconSearch,
			IconClose,
		},
		computed: {
			...mapGetters(AUTH_NAMESPACE, {
				isAuthenticated: AuthGetterTypes.IS_AUTHENTICATED,
			}),
			...mapState(LIBRARY_NAMESPACE, {
				librarySearch: (state: IAssetsLibraryState) => state.librarySearch,
				searchSuggestions: (state: IAssetsLibraryState) => state.searchSuggestions,
				isLoadingSearchSuggestions: (state: IAssetsLibraryState) => state.isLoadingSearchSuggestions,
				showPackOrElement: (state: IAssetsLibraryState) => state.showPackOrElement,
			}),
		},
		directives: {
			highlight: (el, binding) => highlightTextDirective(el, binding),
		},
	})
	export default class ExploreSearchBar extends Vue {
		@Prop({ default: false }) readonly minimized: boolean;
		@Prop() readonly placeholderText: string;
		@Prop({ default: 'default' }) readonly variant: 'default' | 'mobile' | 'full-width';

		isAuthenticated: ReturnType<AuthGetters[AuthGetterTypes.IS_AUTHENTICATED]>;
		librarySearch: IAssetsLibraryState['librarySearch'];
		showPackOrElement: IAssetsLibraryState['showPackOrElement'];
		searchSuggestions: IAssetsLibraryState['searchSuggestions'];

		searchQuery = '';
		minimizedState = false;
		searchInputFocused = false;
		componentKey = 0;

		suggestionCancelToken: CancelTokenSource = null;

		$refs: {
			searchInput: HTMLInputElement;
			refSearchBarSuggestion: SearchBarSuggestion;
		};

		get allowSearchSuggestions() {
			if (this.searchQuery) {
				return this.searchInputFocused && this.searchSuggestions.length > 0;
			}
			return this.searchInputFocused;
		}

		get showRecentSearchItems() {
			return this.isAuthenticated && !this.searchQuery;
		}

		@Watch('librarySearch')
		onLibrarySearchChange() {
			this.handleSyncSearch();
		}

		@Watch('searchInputFocused')
		handleHeaderHighlight() {
			this.$emit('isSearchInputActive', this.searchInputFocused);
		}

		onSearchbarFocus() {
			this.componentKey = 1;
			this.searchInputFocused = true;
		}

		handleCloseSuggestion() {
			this.$refs.searchInput.blur();
			this.searchInputFocused = false;
		}

		handleSyncSearch() {
			if (this.searchQuery !== this.librarySearch) {
				this.searchQuery = this.librarySearch;
			}
		}

		toggleMinimized(minimized: boolean) {
			this.minimizedState = minimized;
			LibraryMutations.updateSuggestionsList(this.$store, []);
			this.$emit('onMinimized', minimized);
		}

		openSearchInput() {
			this.toggleMinimized(false);
			this.$refs.searchInput.focus();
		}

		onSearchClickOutside() {
			if (this.minimized && !this.minimizedState) {
				this.toggleMinimized(true);
			}
		}

		submitSearch() {
			if (this.suggestionCancelToken) {
				this.suggestionCancelToken.cancel();
				this.suggestionCancelToken = null;
			}
			LibraryMutations.updateSuggestionsList(this.$store, []);
			this.$pixcap.$mixpanel.track('Search Library');
			this.$refs.searchInput.blur();
			if (this.searchQuery) {
				if (this.isAuthenticated) {
					this.$refs.refSearchBarSuggestion.recentSearches.unshift(this.searchQuery);
					LibraryActions.updateRecentSearchKeywords(this.$store, { keyword: this.searchQuery, action: 'add' });
				}
				const transformedSearchWord = transformSearchWord(this.searchQuery);
				this.$router.push({
					name: 'LibraryExploreSearchList',
					params: {
						tab: this.showPackOrElement === SEARCH_TAB.PACKS ? SEARCH_ROUTES.PACK_ALL_ASSET : SEARCH_ROUTES.ITEM_ALL_ASSETS,
						searchQuery: transformedSearchWord,
					},
				});
			} else {
				this.$router.push({
					name: 'LibraryExploreList',
					query: this.$route.query,
				});
			}
		}

		onSelectSuggestion(suggestion: string) {
			this.handleCloseSuggestion();
			this.searchQuery = suggestion;
			this.submitSearch();
		}

		onClearSearch() {
			this.searchQuery = '';
			this.$refs.searchInput.focus();
		}

		created() {
			this.toggleMinimized(this.minimized);
			this.handleSyncSearch();
		}
	}
