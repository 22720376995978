import { BlogLang } from '@/lang/blog/_blog_lang_template.type';

const tr: BlogLang = {
	blog: 'Blog',
	blog_show_all: 'Tümünü okuyun',
	blog_banner_title: "15.000'den fazla özelleştirilebilir 3B tasarım varlığı",
	blog_banner_sub: 'UI/UX, web sitesi, uygulama tasarımı ve daha fazlası için.',
	blog_banner_cta: 'Ücretsiz kaydolun',
	blog_article_cta_text: 'Düzenlenebilir 3D simgelere, resimlere, karakterlere ve maketlere mi ihtiyacınız var?',
	blog_article_cta_button_text: 'Şimdi tasarlayın',
};

export default tr;
