import { BlogLang } from '@/lang/blog/_blog_lang_template.type';

const de: BlogLang = {
	blog: 'Blog',
	blog_show_all: 'Alle anzeigen',
	blog_banner_title: 'Über 15.000 anpassbare 3D-Design-Assets',
	blog_banner_sub: 'für UI/UX, Website, App-Design und mehr.',
	blog_banner_cta: 'Kostenlos anmelden',
	blog_article_cta_text: 'Benötigen Sie bearbeitbare 3D-Symbole, Illustrationen, Charaktere und Modelle?',
	blog_article_cta_button_text: 'Jetzt entwerfen',
};

export default de;
