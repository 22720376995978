
	import { Component, Vue, Prop } from 'vue-property-decorator';
	import BodyText from '@pixcap/ui-library/components/Typography/BodyText.vue';
	import HeadingText from '@pixcap/ui-library/components/Typography/HeadingText.vue';
	import PopupMenu from '@pixcap/ui-library/components/PopupMenu.vue';
	import Button from '@pixcap/ui-library/components/Button.vue';
	import IconArrowDown from '@pixcap/ui-library/components/Icons/IconArrowDown.vue';
	import IconCheck from '@pixcap/ui-library/components/Icons/IconCheck.vue';
	import CaptionText from '@pixcap/ui-library/components/Typography/CaptionText.vue';

	@Component({
		name: 'FilterInput',
		components: {
			CaptionText,
			Button,
			BodyText,
			HeadingText,
			PopupMenu,
			IconArrowDown,
			IconCheck,
		},
		computed: {},
	})
	export default class FilterInput extends Vue {
		@Prop() filterOptions: { name: string; value: any; icon?: Vue }[];
		@Prop() activeValue: any;
		@Prop() isMiniVariant: boolean;
		@Prop({ default: 'center' }) openPosition: string;
		@Prop({ default: 'default' }) size: 'small' | 'default';

		@Prop() placeholder: string;
		@Prop() tooltip: string;
		@Prop() prefixText: string;
		@Prop() label: string;
		@Prop() beforePlaceholderIcon: Vue;
		@Prop() placeholderIcon: Vue;

		showFilterMenu = false;

		get activeOption() {
			const filterOptions = this.filterOptions;
			return filterOptions.find((option) => option.value === this.activeValue) || filterOptions[0];
		}

		toggleFilterMenu(showFilterMenu: boolean) {
			this.showFilterMenu = showFilterMenu;
			this.$emit('showMenu', showFilterMenu);
		}

		onFilterChange(value: string) {
			this.$emit('onFilterChange', value);
		}
	}
