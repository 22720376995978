import { BlogLang } from '@/lang/blog/_blog_lang_template.type';

const jp: BlogLang = {
	blog: 'ブログ',
	blog_show_all: 'すべて読む',
	blog_banner_title: '15,000 以上のカスタマイズ可能な 3D デザイン アセット',
	blog_banner_sub: 'UI/UX、Web サイト、アプリ デザインなどに使用できる',
	blog_banner_cta: '無料会員登録',
	blog_article_cta_text: '3Dモックアップ、アイコン、イラスト、編集可能なテンプレートなどが必要ですか?',
	blog_article_cta_button_text: '今すぐデザイン',
};

export default jp;
