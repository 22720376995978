
	import { Component, Vue, InjectReactive, Inject } from 'vue-property-decorator';
	import IconArrowUp from '@pixcap/ui-library/components/Icons/IconArrowUp.vue';
	@Component({
		name: 'BackToTopButton',
		components: {
			IconArrowUp,
		},
	})
	export default class BackToTopButton extends Vue {
		@InjectReactive() isScrolled: boolean;
		@Inject() handleScrollBack: (isSmoothBehavior: boolean) => void;
	}
