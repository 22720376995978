import en from './blog_en';
import es from './blog_es';
import de from './blog_de';
import br from './blog_br';
import tr from './blog_tr';
import id from './blog_id';
import kr from './blog_kr';
import jp from './blog_jp';
import ru from './blog_ru';
import fr from './blog_fr';

export default {
	en,
	es,
	de,
	br,
	tr,
	id,
	kr,
	jp,
	ru,
	fr,
};
