
	import { Component, Inject, InjectReactive, Mixins, Prop, Watch } from 'vue-property-decorator';
	import { mapGetters, mapState } from 'vuex';
	import Button from '@pixcap/ui-library/components/Button/Button2.vue';
	import PixcapLogo from '@pixcap/ui-library/components/Assets/PixcapLogo.vue';
	import AuthenticationModalMixin from '@pixcap/ui-core/mixins/AuthenticationModalMixin';
	import UserInfoAvatar from '@pixcap/ui-core/components/account/common/UserInfoAvatar.vue';

	import GoogleAutomaticLogin from '@pixcap/ui-core/components/account/common/GoogleAutomaticLogin.vue';
	import ServerDownloadPopup from '@pixcap/ui-core/components/download-queue/ServerDownloadPopup.vue';
	import {
		Getters as UserGetters,
		GetterTypes as UserGetterTypes,
		NAMESPACE as USER_NAMESPACE,
		UserState,
	} from '@pixcap/ui-core/models/store/user.interface';
	import { EVENTS_MANAGER_COMPONENT as EMC, EventType } from '@pixcap/ui-core/models/widgets/windowEventsManager.interface';
	import IconSearch from '@pixcap/ui-library/components/Icons/IconSearch.vue';
	import { actionsWrapper as ExportActions, mutationsWrapper as ExportMutations } from '@pixcap/ui-core/store/projectexports';
	import BodyText from '@pixcap/ui-library/components/Typography/BodyText.vue';
	import IconCaretLeft from '@pixcap/ui-library/components/Icons/IconCaretLeft.vue';
	import ExploreSearchBar from '@/components/layouts/default/ExploreSearchBar.vue';
	import { IAssetsLibraryState, LIBRARY_TAB, NAMESPACE as LIBRARY_NAMESPACE } from '@/models/store/pixcapassets.interface';
	import AppLocaleSwitcher from '@/components/pixcap-library/shared/dropdown-filter/AppLocaleSwitcher.vue';
	import HeaderItem from '@/components/pixcap-library/shared/header/HeaderItem.vue';
	import { mutationsWrapper as LibraryMutations } from '@/store/pixcapassets/wrapper';
	import ExploreHeaderHamburger from '@/components/layouts/default/ExploreHeaderHamburger.vue';
	import { IOrganismState, NAMESPACE as ORGANISM_NAMESPACE } from '@/models/store/organism.interface';
	import { mutationsWrapper as OrganismMutations } from '@/store/organism/wrapper';

	@Component({
		name: 'ExploreHeader',
		components: {
			IconCaretLeft,
			ExploreHeaderHamburger,
			BodyText,
			HeaderItem,
			AppLocaleSwitcher,
			IconSearch,
			ExploreSearchBar,
			Button,
			GoogleAutomaticLogin,
			PixcapLogo,
			UserInfoAvatar,
			ServerDownloadPopup,
		},
		computed: {
			...mapState(LIBRARY_NAMESPACE, {
				librarySearch: (state: IAssetsLibraryState) => state.librarySearch,
			}),
			...mapState(USER_NAMESPACE, {
				personalData: (state: UserState) => state.personalData,
			}),
			...mapState(ORGANISM_NAMESPACE, {
				shouldHighlightHeader: (state: IOrganismState) => state.shouldHighlightHeader,
			}),
			...mapGetters(USER_NAMESPACE, {
				isProOrAbove: UserGetterTypes.IS_PRO_OR_ABOVE,
				isNewComer: UserGetterTypes.IS_NEW_COMER,
			}),
		},
	})
	export default class ExploreHeader extends Mixins(AuthenticationModalMixin) {
		personalData: UserState['personalData'];
		librarySearch: IAssetsLibraryState['librarySearch'];
		shouldHighlightHeader: IOrganismState['shouldHighlightHeader'];

		isProOrAbove: ReturnType<UserGetters[UserGetterTypes.IS_PRO_OR_ABOVE]>;
		isNewComer: ReturnType<UserGetters[UserGetterTypes.IS_NEW_COMER]>;

		@InjectReactive() isMobileScreen: boolean;
		@InjectReactive() isTabletScreen: boolean;
		@InjectReactive() isDesktopScreen: boolean;
		@InjectReactive() isScrolled: boolean;
		@Inject() handleScrollBack: (isSmoothBehavior: boolean) => void;

		@Watch('librarySearch', { immediate: true })
		handleHideLogo(val) {
			this.showSearchBar = !!val;
		}

		@Watch('isAuthenticated')
		onIsAuthenticatedChange(next, prev) {
			if (!prev && next) {
				ExportMutations.resetServerExportItems(this.$store);
				ExportActions.subscribeToServerRenderProgress(this.$store);
			}
		}

		@Watch('isSearchInputActive')
		@Watch('isAppLocaleActive')
		@Watch('isServerDownloadPopupActive')
		@Watch('isUserMenuActive')
		handleHighlightHeader() {
			if (this.isSearchInputActive || this.isAppLocaleActive || this.isServerDownloadPopupActive || this.isUserMenuActive) {
				OrganismMutations.setShouldHighlightHeader(this.$store, true);
			} else {
				OrganismMutations.setShouldHighlightHeader(this.$store, false);
			}
		}

		LIBRARY_TAB = LIBRARY_TAB;
		_handleScreenSize;
		showLoadingState = true;
		shouldMinimizeNav = null;
		showSearchBar = false;

		isSearchInputActive = false;
		isAppLocaleActive = false;
		isServerDownloadPopupActive = false;
		isUserMenuActive = false;

		callbackName = 'header.resizeEvent';

		$refs: {
			refExploreHamburger: ExploreHeaderHamburger;
			refExploreSearchBar: ExploreSearchBar;
		};

		get pricingCtaTag() {
			if (this.isNewComer && !this.isProOrAbove) {
				return this.$t('common_elements.discount_text', { number: 10 });
			} else {
				return null;
			}
		}

		get headerItems() {
			if (this.shouldMinimizeNav) {
				return [
					{
						title: this.$t('navbar.navigation_items.browse'),
						childrens: [
							{
								prefixText: this.$t('navbar.navigation_items.asset'),
								content: [
									{
										title: this.$t('navbar.navigation_items.asset_dropdown.icons_title'),
										to: { name: 'LibraryPage', params: { tab: LIBRARY_TAB.THREE_D_PACKS } },
										handler: () => {
											this.navigationItemHandler(LIBRARY_TAB.THREE_D_PACKS);
										},
									},
									{
										title: this.$t('navbar.navigation_items.asset_dropdown.characters_title'),
										to: { name: 'LibraryPage', params: { tab: LIBRARY_TAB.THREE_D_CHARACTERS } },
										handler: () => {
											this.navigationItemHandler(LIBRARY_TAB.THREE_D_CHARACTERS);
										},
									},
									{
										title: this.$t('navbar.navigation_items.asset_dropdown.mockups_title'),
										to: { name: 'LibraryPage', params: { tab: LIBRARY_TAB.MOCKUPS } },
										handler: () => {
											this.navigationItemHandler(LIBRARY_TAB.MOCKUPS);
										},
									},
								],
							},
							{
								prefixText: this.$t('navbar.navigation_items.tools_dropdown.prefix'),
								content: [
									{
										title: this.$t('navbar.navigation_items.tools_dropdown.figma_plugin'),
										tag: this.$t('common_elements.new'),
										external: true,
										href: `${process.env.ROOT_URL}/tools/3d-character-icon-plugin-for-figma`,
									},
									{
										title: this.$t('navbar.navigation_items.tools_dropdown.ai_3d_model'),
										external: true,
										href: `${process.env.ROOT_URL}/tools/ai-3d-model-generator`,
									},
									{
										title: this.$t('navbar.navigation_items.tools_dropdown.ai_character'),
										external: true,
										href: `${process.env.ROOT_URL}/tools/ai-character-generator`,
									},
									{
										title: this.$t('navbar.navigation_items.tools_dropdown.ai_girl'),
										external: true,
										href: `${process.env.ROOT_URL}/tools/ai-girl-generator`,
									},
									{
										title: this.$t('navbar.navigation_items.tools_dropdown.ai_background'),
										external: true,
										href: `${process.env.ROOT_URL}/tools/ai-background-generator`,
									},
									{
										title: this.$t('navbar.navigation_items.tools_dropdown.ai_filter'),
										external: true,
										href: `${process.env.ROOT_URL}/tools/ai-filter`,
									},
									{
										title: this.$t('navbar.navigation_items.tools_dropdown.ai_icon'),
										external: true,
										href: `${process.env.ROOT_URL}/tools/ai-icon-generator`,
									},
									{
										title: this.$t('navbar.navigation_items.tools_dropdown.icon_maker'),
										external: true,
										href: `${process.env.ROOT_URL}/tools/3d-icons`,
									},
									{
										title: this.$t('navbar.navigation_items.tools_dropdown.animated_icon'),
										external: true,
										href: `${process.env.ROOT_URL}/tools/animated-icons`,
									},
									{
										title: this.$t('navbar.navigation_items.tools_dropdown.character_creator'),
										external: true,
										href: `${process.env.ROOT_URL}/tools/3d-character-creator`,
									},
								],
							},
						],
					},
				];
			}
			return [
				{
					title: this.$t('navbar.navigation_items.asset'),
					content: [
						{
							title: this.$t('navbar.navigation_items.asset_dropdown.icons_title'),
							description: this.$t('navbar.navigation_items.asset_dropdown.icons_description'),
							thumbnailUrl: `${process.env.ROOT_URL}/cdn/explore/homepage/item-cards/icons.mp4`,
							isVideoThumbnail: true,
							to: { name: 'LibraryPage', params: { tab: LIBRARY_TAB.THREE_D_PACKS } },
							handler: () => {
								this.navigationItemHandler(LIBRARY_TAB.THREE_D_PACKS);
							},
						},
						{
							title: this.$t('navbar.navigation_items.asset_dropdown.characters_title'),
							description: this.$t('navbar.navigation_items.asset_dropdown.characters_description'),
							thumbnailUrl: `${process.env.ROOT_URL}/cdn/explore/homepage/item-cards/characters.webp`,
							isVideoThumbnail: false,
							to: { name: 'LibraryPage', params: { tab: LIBRARY_TAB.THREE_D_CHARACTERS } },
							handler: () => {
								this.navigationItemHandler(LIBRARY_TAB.THREE_D_CHARACTERS);
							},
						},
						{
							title: this.$t('navbar.navigation_items.asset_dropdown.mockups_title'),
							description: this.$t('navbar.navigation_items.asset_dropdown.mockups_description'),
							thumbnailUrl: `${process.env.ROOT_URL}/cdn/explore/homepage/item-cards/mockups.webp`,
							isVideoThumbnail: false,
							to: { name: 'LibraryPage', params: { tab: LIBRARY_TAB.MOCKUPS } },
							handler: () => {
								this.navigationItemHandler(LIBRARY_TAB.MOCKUPS);
							},
						},
					],
					footer: {
						title: this.$t('navbar.navigation_items.asset_dropdown.explore_all'),
						to: { name: 'LibraryPage', params: { tab: LIBRARY_TAB.ALL_ASSETS } },
						handler: () => {
							this.navigationItemHandler(LIBRARY_TAB.ALL_ASSETS);
						},
					},
				},
				{
					title: this.$t('navbar.navigation_items.tools'),
					content: [
						{
							title: this.$t('navbar.navigation_items.tools_dropdown.figma_plugin'),
							tag: this.$t('common_elements.new'),
							external: true,
							href: `${process.env.ROOT_URL}/tools/3d-character-icon-plugin-for-figma`,
						},
						{
							title: this.$t('navbar.navigation_items.tools_dropdown.ai_3d_model'),
							external: true,
							href: `${process.env.ROOT_URL}/tools/ai-3d-model-generator`,
						},
						{
							title: this.$t('navbar.navigation_items.tools_dropdown.ai_character'),
							external: true,
							href: `${process.env.ROOT_URL}/tools/ai-character-generator`,
						},
						{
							title: this.$t('navbar.navigation_items.tools_dropdown.ai_girl'),
							external: true,
							href: `${process.env.ROOT_URL}/tools/ai-girl-generator`,
						},
						{
							title: this.$t('navbar.navigation_items.tools_dropdown.ai_background'),
							external: true,
							href: `${process.env.ROOT_URL}/tools/ai-background-generator`,
						},
						{
							title: this.$t('navbar.navigation_items.tools_dropdown.ai_filter'),
							external: true,
							href: `${process.env.ROOT_URL}/tools/ai-filter`,
						},
						{
							title: this.$t('navbar.navigation_items.tools_dropdown.ai_icon'),
							external: true,
							href: `${process.env.ROOT_URL}/tools/ai-icon-generator`,
						},
						{
							title: this.$t('navbar.navigation_items.tools_dropdown.icon_maker'),
							external: true,
							href: `${process.env.ROOT_URL}/tools/3d-icons`,
						},
						{
							title: this.$t('navbar.navigation_items.tools_dropdown.animated_icon'),
							external: true,
							href: `${process.env.ROOT_URL}/tools/animated-icons`,
						},
						{
							title: this.$t('navbar.navigation_items.tools_dropdown.character_creator'),
							external: true,
							href: `${process.env.ROOT_URL}/tools/3d-character-creator`,
						},
					],
				},
			];
		}

		get isSyncClientUserState() {
			return this.showLoadingState || (this.isAuthenticated && !this.personalData);
		}

		get shouldHideSearchBar() {
			return this.$route.name === 'LibraryExploreList';
		}

		handleSearchInputActive(val) {
			this.isSearchInputActive = val;
		}

		handleHideSearchBar(val) {
			if (!val && !this.librarySearch) this.showSearchBar = false;
		}

		handleAppLocaleActive(val) {
			this.isAppLocaleActive = val;
		}

		handleRedirectHomepage() {
			if (this.isAuthenticated) {
				if (this.$route.name === 'LibraryPage') {
					this.handleScrollBack(true);
				} else {
					this.handleScrollBack(true);
					this.$router.push({ name: 'LibraryPage', params: { tab: LIBRARY_TAB.ALL_ASSETS } });
				}
				return;
			}
			if (this.$route.name === 'LibraryExploreList') {
				this.handleScrollBack(true);
			} else {
				this.handleScrollBack(true);
				this.$router.push({ name: 'LibraryExploreList' });
			}
		}

		navigationItemHandler(key) {
			LibraryMutations.setExplorerTab(this.$store, key);
			if (key === LIBRARY_TAB.THREE_D_CHARACTERS) {
				LibraryMutations.updateShowOnlyAnimatedFiles(this.$store, null);
			}
			if (this.$route.name === 'LibraryPage') {
				const libraryRoute = this.$router.resolve(key);
				window.history.replaceState({}, '', libraryRoute.href);
			} else {
				this.$router.push({ name: 'LibraryPage', params: { tab: key } });
			}
		}

		checkMinimizeNav() {
			const windowWidth = window.innerWidth;
			return windowWidth <= 1200;
		}

		handleSearch() {
			this.showSearchBar = true;
			this.$nextTick(() => {
				this.$refs.refExploreSearchBar.$refs.searchInput.focus();
			});
		}

		created() {
			if (this.isAuthenticated) {
				ExportMutations.resetServerExportItems(this.$store);
				ExportActions.subscribeToServerRenderProgress(this.$store);
			}
		}

		mounted() {
			this.showLoadingState = false;
			const { windowEventsManager } = this.$pixcap.$widgets;
			windowEventsManager?.registerEventListener(EventType.RESIZE, {
				domain: EMC.DEFAULT,
				callback: () => {
					this.shouldMinimizeNav = this.checkMinimizeNav();
				},
				callbackName: this.callbackName,
			});
			this.shouldMinimizeNav = this.checkMinimizeNav();
		}

		beforeDestroy() {
			const { windowEventsManager } = this.$pixcap.$widgets;
			windowEventsManager.deregisterEventListener(EventType.RESIZE, EMC.DEFAULT, this.callbackName);
		}
	}
