import { BlogLang } from '@/lang/blog/_blog_lang_template.type';

const es: BlogLang = {
	blog: 'Blog',
	blog_show_all: 'Leer todo',
	blog_banner_title: 'Más de 15.000 recursos de diseño 3D personalizables',
	blog_banner_sub: 'para UI/UX, sitios web, diseño de aplicaciones y mucho más.',
	blog_banner_cta: 'Regístrate gratis',
	blog_article_cta_text: '¿Necesita iconos, ilustraciones, personajes y maquetas editables en 3D?',
	blog_article_cta_button_text: 'Diseña ahora',
};

export default es;
