import { BlogLang } from '@/lang/blog/_blog_lang_template.type';

const fr: BlogLang = {
	blog: 'Blog',
	blog_show_all: 'Voir tout',
	blog_banner_title: '15 000 éléments de conception 3D personnalisables',
	blog_banner_sub: "pour l'UI/UX, les sites web, la conception d'applications et plus encore.",
	blog_banner_cta: "S'inscrire gratuitement",
	blog_article_cta_text: "Vous avez besoin d'icônes, d'illustrations, de personnages et de maquettes 3D modifiables?",
	blog_article_cta_button_text: 'Conception maintenant',
};

export default fr;
