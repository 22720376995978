import { AppLocales } from '@pixcap/ui-core/models/store/app.interface';

export const EXPLORE_ROUTE_NAMES = ['ExplorePage', 'ExploreSearchOrPreviewer', 'PacksList', 'PacksDetail'];

export const BACK_TO_TOP_SCROLL_OFFSET = 100;

export const DEFAULT_APP_LOCALE = 'en';

const ENG_LOCALE = {
	name: 'English',
	locale_code: AppLocales.EN, // i18n locale
	hreflang: 'en',
	og_locale_code: 'en_US',
};

export const APP_AVAILABLE_LOCALES = [
	{
		name: 'Spanish',
		locale_code: AppLocales.ES,
		hreflang: 'es',
		og_locale_code: 'es_ES',
	},
	{
		name: 'German',
		locale_code: AppLocales.DE,
		hreflang: 'de',
		og_locale_code: 'de_DE',
	},
	{
		name: 'Portuguese',
		locale_code: AppLocales.BR,
		hreflang: 'pt-br',
		og_locale_code: 'pt_BR',
	},
	{
		name: 'Korean',
		locale_code: AppLocales.KR,
		hreflang: 'ko',
		og_locale_code: 'ko_KR',
	},
	{
		name: 'Japanese',
		locale_code: AppLocales.JP,
		hreflang: 'ja',
		og_locale_code: 'ja_JP',
	},
];

export const ALL_APP_LOCALES = [ENG_LOCALE, ...APP_AVAILABLE_LOCALES];

export const APP_AVAILABLE_LOCALE_ROUTE_PARAMS = APP_AVAILABLE_LOCALES.map((locale) => locale.locale_code);
