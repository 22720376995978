import { BlogLang } from '@/lang/blog/_blog_lang_template.type';

const id: BlogLang = {
	blog: 'Blog',
	blog_show_all: 'Baca semua',
	blog_banner_title: '15.000+ aset desain 3D yang dapat disesuaikan',
	blog_banner_sub: 'untuk UI/UX, situs web, desain aplikasi, dan lainnya.',
	blog_banner_cta: 'Daftar gratis',
	blog_article_cta_text: 'Perlu ikon, ilustrasi, karakter, dan maket 3D yang dapat diedit?',
	blog_article_cta_button_text: 'Desain sekarang',
};

export default id;
