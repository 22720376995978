import { BlogLang } from '@/lang/blog/_blog_lang_template.type';

const en: BlogLang = {
	blog: 'Blog',
	blog_show_all: 'Show all',
	blog_banner_title: '15,000+ customizable 3D design assets',
	blog_banner_sub: 'for UI/UX, website, app design and more',
	blog_banner_cta: 'Sign up for free',
	blog_article_cta_text: 'Need editable 3D icons, illustrations, characters, and mockups?',
	blog_article_cta_button_text: 'Design now',
};

export default en;
