export const DEFAULT_BLOG_LOCALE = 'en';

export const AVAILABLE_BLOG_LOCALES = [
	{
		name: 'English',
		route_params: 'en',
		hreflang: 'en',
		strapi_code: 'en',
		og_locale_code: 'en_US',
	},
	{
		name: 'Español',
		route_params: 'es',
		hreflang: 'es',
		strapi_code: 'es',
		og_locale_code: 'es_ES',
	},
	{
		name: 'Deutsch',
		route_params: 'de',
		hreflang: 'de',
		strapi_code: 'de',
		og_locale_code: 'de_DE',
	},
	{
		name: 'Português',
		route_params: 'br',
		hreflang: 'pt-br',
		strapi_code: 'pt-BR',
		og_locale_code: 'pt_BR',
	},
	{
		name: 'Türkçe',
		route_params: 'tr',
		hreflang: 'tr',
		strapi_code: 'tr',
		og_locale_code: 'tr_TR',
	},
	{
		name: 'Bahasa Indonesia',
		route_params: 'id',
		hreflang: 'id',
		strapi_code: 'id',
		og_locale_code: 'id_ID',
	},
	{
		name: '한국어',
		route_params: 'kr',
		hreflang: 'ko',
		strapi_code: 'ko',
		og_locale_code: 'ko_KR',
	},
	{
		name: '日本語',
		route_params: 'jp',
		hreflang: 'ja',
		strapi_code: 'ja',
		og_locale_code: 'ja_JP',
	},
	{
		name: 'Français',
		route_params: 'fr',
		hreflang: 'fr',
		strapi_code: 'fr',
		og_locale_code: 'fr_FR',
	},
	{
		name: 'Русский',
		route_params: 'ru',
		hreflang: 'ru',
		strapi_code: 'ru',
		og_locale_code: 'ru_RU',
	},
];

export const AVAILABLE_BLOG_LOCALE_ROUTE_PARAMS = AVAILABLE_BLOG_LOCALES.map((locale) => locale.route_params);
