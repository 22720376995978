import { BlogLang } from '@/lang/blog/_blog_lang_template.type';

const ru: BlogLang = {
	blog: 'Блог',
	blog_show_all: 'Смотреть все',
	blog_banner_title: '15 000 настраиваемых активов 3D-дизайна',
	blog_banner_sub: 'для дизайна UI/UX, веб-сайтов, приложений и многого другого.',
	blog_banner_cta: 'Зарегистрироваться бесплатно',
	blog_article_cta_text: 'Нужны редактируемые 3D-иконки, иллюстрации, персонажи и макеты?',
	blog_article_cta_button_text: 'Дизайн сейчас',
};

export default ru;
