
	import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
	import BodyText from '@pixcap/ui-library/components/Typography/BodyText.vue';
	import CaptionText from '@pixcap/ui-library/components/Typography/CaptionText.vue';
	import IconSearch from '@pixcap/ui-library/components/Icons/IconSearch.vue';
	import { mapGetters, mapState } from 'vuex';
	import axios, { CancelTokenSource } from 'axios';
	import { actionsWrapper as LibraryActions, mutationsWrapper as LibraryMutations } from '@/store/pixcapassets/wrapper';
	import { IAssetsLibraryState, NAMESPACE as LIBRARY_NAMESPACE } from '@/models/store/pixcapassets.interface';
	import IconClose from '@pixcap/ui-library/components/Icons/IconClose.vue';
	import { Getters as AuthGetters, GetterTypes as AuthGetterTypes, NAMESPACE as AUTH_NAMESPACE } from '@pixcap/ui-core/models/store/auth.interface';

	const CancelToken = axios.CancelToken;

	@Component({
		components: {
			IconClose,
			IconSearch,
			CaptionText,
			BodyText,
		},
		computed: {
			...mapGetters(AUTH_NAMESPACE, {
				isAuthenticated: AuthGetterTypes.IS_AUTHENTICATED,
			}),
			...mapState(LIBRARY_NAMESPACE, {
				searchSuggestions: (state: IAssetsLibraryState) => state.searchSuggestions,
				isLoadingSearchSuggestions: (state: IAssetsLibraryState) => state.isLoadingSearchSuggestions,
			}),
		},
	})
	export default class SearchBarSuggestion extends Vue {
		searchSuggestions: IAssetsLibraryState['searchSuggestions'];
		isLoadingSearchSuggestions: IAssetsLibraryState['isLoadingSearchSuggestions'];

		isAuthenticated: ReturnType<AuthGetters[AuthGetterTypes.IS_AUTHENTICATED]>;

		@Prop() showRecentSearchItems: boolean;
		@Prop() searchQuery: string | null;

		@Watch('searchQuery')
		handleFetchSuggestedQuery(val) {
			if (!val) {
				LibraryMutations.updateSuggestionsList(this.$store, []);
			} else {
				this.fetchSuggestions();
			}
		}

		recentSearches = [];
		isFetchingKeywords = false;
		suggestionCancelToken: CancelTokenSource = null;

		handleCloseModal() {
			this.$emit('close');
		}

		fetchSuggestions() {
			if (this.suggestionCancelToken) {
				this.suggestionCancelToken.cancel('Cancel suggestions request');
				this.suggestionCancelToken = null;
			}

			this.suggestionCancelToken = CancelToken.source();

			LibraryActions.fetchLibrarySearchSuggestions(this.$store, {
				size: 5,
				search: this.searchQuery,
				cancelToken: this.suggestionCancelToken.token,
			}).finally(() => {
				if (this.suggestionCancelToken) this.suggestionCancelToken = null;
			});
		}

		async fetch() {
			await this.fetchRecentSearchKeyword();
		}

		async fetchRecentSearchKeyword() {
			if (!this.isAuthenticated) return;
			if (this.isFetchingKeywords) return;
			this.isFetchingKeywords = true;
			this.recentSearches = [];
			const response = await LibraryActions.fetchRecentSearchKeywords(this.$store);
			if (response?.content.length > 0) {
				this.recentSearches = response.content;
			}
			this.isFetchingKeywords = false;
		}

		onSelectSuggestion(suggestion: string) {
			this.$emit('selectSuggestion', suggestion);
		}

		onRemoveKeyword(e, keyword: string) {
			e.preventDefault();
			e.stopPropagation();
			this.recentSearches = this.recentSearches.filter((item) => item !== keyword);
			LibraryActions.updateRecentSearchKeywords(this.$store, { keyword, action: 'remove' });
		}

		mounted() {
			this.fetchRecentSearchKeyword();
		}
	}
