
	import { Vue, Component } from 'vue-property-decorator';
	import PixcapLogo from '@pixcap/ui-library/components/Assets/PixcapLogo.vue';

	@Component({
		name: 'BlogFooter',
		components: { PixcapLogo },
	})
	export default class BlogFooter extends Vue {
		row1 = [
			{
				name: 'Explore',
				items: [
					{ name: '3D Templates', url: 'https://pixcap.com/templates' },
					{ name: '3D Icon Packs', url: 'https://pixcap.com/3d-icon-packs' },
					{ name: '3D Illustrations', url: 'https://pixcap.com/3d-illustrations' },
					{ name: '3D Mockups', url: 'https://pixcap.com/mockups' },
					{ name: 'Events', url: 'https://pixcap.com/events' },
				],
			},
			{
				name: 'Features',
				items: [
					{ name: 'Animated Mockups', url: 'https://pixcap.com/animated-mockups-marketing' },
					{ name: 'Pixcap AI', url: 'https://pixcap.com/ai' },
					{ name: 'Character Library', url: 'https://pixcap.com/character-library' },
					{ name: 'Material Library', url: 'https://pixcap.com/material-library' },
				],
			},
			{
				name: 'Company',
				items: [
					{ name: 'Pricing', url: 'https://pixcap.com/contact-us' },
					{ name: 'Enterprise', url: 'https://pixcap.gitbook.io/pixcap-docs/legal/terms' },
					{ name: 'Privacy Policy', url: 'https://pixcap.gitbook.io/pixcap-docs/legal/privacy-policy' },
					{ name: 'Terms of Service', url: 'https://pixcap.com/enterprise' },
					{ name: 'Contact Us', url: 'https://pixcap.com/pricing' },
				],
			},
			{
				name: 'Resources',
				items: [
					{ name: 'Blog', url: 'https://pixcap.com/blog' },
					{ name: 'Glossary', url: 'https://pixcap.com/glossary' },
					{ name: 'Become A Contributor', url: 'https://pixcap.com/creators' },
				],
			},
		];

		row2 = [
			{
				name: 'Tools',
				items: [
					{ name: 'AI 3D Model Generator', url: 'https://pixcap.com/tools/ai-3d-model-generator' },
					{ name: 'AI Girl Generator', url: 'https://pixcap.com/tools/ai-girl-generator' },
					{ name: 'AI Background Generator', url: 'https://pixcap.com/tools/ai-background-generator' },
					{ name: 'AI Icon Generator', url: 'https://pixcap.com/tools/ai-icon-generator' },
					{ name: '3D Icons Maker', url: 'https://pixcap.com/tools/3d-icons' },
					{ name: 'Animated Icon', url: 'https://pixcap.com/tools/animated-icons' },
				],
			},
			{
				name: 'Create',
				items: [
					{ name: 'Mug Design', url: 'https://pixcap.com/create/mug-templates' },
					{ name: 'Sticker Design', url: 'https://pixcap.com/create/sticker-templates' },
					{ name: 'Friendsgiving Invitation', url: 'https://pixcap.com/create/friendsgiving-invitation' },
					{ name: 'Poster Mockup', url: 'https://pixcap.com/create/poster-mockups' },
					{ name: 'Business Card Mockup', url: 'https://pixcap.com/create/business-card-mockups' },
					{ name: 'iPhone Mockup', url: 'https://pixcap.com/create/iphone-mockups' },
				],
			},
		];
	}
